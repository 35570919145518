import React from "react";
import { uniqueId } from "lodash";
import { getColor } from "../helpers";
import { Loading } from "../../shared";
import SVG from "../../../images/selector.svg";

export default ({ list, handleOnClick }) => {
  if (!list) {
    return <Loading />;
  }
  return (
    <div className="process-bar">
      {list.map((item) => {
        console.log(item);

        return (
          <div
            key={uniqueId("process-bar-div")}
            style={{
              backgroundColor: getColor(item.paymenttype),
              width: `${item.percentage}%`,
              height: `100%`,
            }}
          />
        );
      })}
      <div className="selector" onClick={handleOnClick}>
        <img src={SVG} alt="" />
      </div>
    </div>
  );
};
