import React from "react";
import loadingImage from "../../images/loading.svg";

export default () => {
  return (
    <div className="component-loading">
      <img src={loadingImage} style={{ height: `60px` }} alt="" />
    </div>
  );
};
